<template>
  <div>
    <column-picker
      :attach="false"
      :label="$t('t.Date')"
      :readonly="readonly"
      :selected-col-id.sync="computedColumnDate"
      :rules="required"
      :dense="dense"
      :filters="{ 'document-types': cameleonDocTypes}"
      currency-disabled
      ref="column-date"
      date-cols-only
      no-cols-relation
    />
    <column-picker
      :attach="false"
      :clearable="true"
      :label="$t('t.Sum')"
      :filters="{ 'document-types': cameleonDocTypes}"
      :readonly="readonly"
      :selected-col-id.sync="computedColumnSum"
      currency-disabled
      :dense="dense"
      amt-cols-only
      no-cols-relation
      no-cols-aging
    />
    <filter-params
      v-if="computedColumnDate"
      v-model="computedFilter"
      :auto-filter-type="computedViewType"
      :readonly="readonly"
      class="mt-4"
      ref="filter-date"
    />
  </div>
</template>

<script>
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  inheritAttrs: false,
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    FilterParams: () => import('@/pages/cameleon/columns/paramsType/filter-params/filter-params')
  },
  computed: {
    computedViewType () {
      return this.viewType
    },
    computedFilter: {
      get () {
        return this.dataFilter
      },
      set (v) {
        this.dataFilter = v
        this.dataDocumentEmit()
      }
    },
    computedColumnDate: {
      get () {
        return this.dataColumnDate
      },
      set (v) {
        this.dataColumnDate = v
        this.dataDocumentEmit()
      }
    },
    computedColumnSum: {
      get () {
        return this.dataColumnSum
      },
      set (v) {
        this.dataColumnSum = v
        this.dataDocumentEmit()
      }
    },
    cameleonDocTypes () {
      const cameleonDocTypes = new IncExc()
      cameleonDocTypes.include([this.computedViewType])
      return cameleonDocTypes
    }
  },
  data () {
    return {
      required: [v => !!v?.length || this.$t('t.IsRequired')],
      dataColumnDate: null,
      dataColumnSum: null,
      dataFilter: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        ty: 'AgingEntityParams',
        c: {
          columnDate: this.dataColumnDate,
          filter: this.dataFilter,
          columnSum: this.dataColumnSum
        }
      }
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    viewType: String,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataColumnDate = v?.c?.columnDate ?? null
        this.dataColumnSum = v?.c?.columnSum ?? null
        this.dataFilter = v?.c?.filter || {}
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
